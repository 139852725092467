import { FhirProgressService } from './../../service/fhir-progress.service';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { ILogin, IProgress } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { withLatestFrom, map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadProgresses, loadProgressesSuccess, loadProgressSuccess, progressFail, upsertProgress, upsertUnscheduledProgress } from './progress.actions';
import { loadActivities } from '../../state-activity/+state/activity.actions';


@Injectable()
export class ProgressEffects {

  constructor(
    private actions$: Actions, private store: Store<IAppMedlogicState>,
    private progressSrv: FhirProgressService,
  ) { }

  loadProgresses$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadProgresses),
      withLatestFrom(this.store),
      mergeMap(([_, __]) => this.progressSrv.getAll<IProgress[]>()),
      map((progresses: IProgress[] | null) =>
        progresses ? loadProgressesSuccess({ progresses }) : progressFail({ error: '' })
      ),
      catchError((e: any) => {
        console.log(e);
        return of(progressFail({ error: e?.message || e }));
      })
    )
  );

  upsertProgress$ = createEffect(() => this.actions$
    .pipe(
      ofType(upsertProgress),
      withLatestFrom(this.store),
      mergeMap(([action, state]) =>
        this.progressSrv.create<IProgress>(
          {
            ...action.progress,
            loginName: (state.loginPWA.selectedLogin as ILogin)?.usuarioLogadoNome
          } as IProgress
        )
      ),
      map((progress: IProgress | null) => {
        return progress ? loadProgressSuccess({ progress }) : progressFail({ error: '' })
      }),
      catchError((e: any) => {
        console.log(e);
        return of(progressFail({ error: e?.message || e }));
      })
    ),
  )

  upsertUnscheduledProgress$ = createEffect(() => this.actions$
    .pipe(
      ofType(upsertUnscheduledProgress),
      withLatestFrom(this.store),
      mergeMap(([action, state]) => this.progressSrv.getActivityByType(action.activityType).pipe(
        mergeMap(activity => {
          const progress = {
            ...action.progress,
            activityIdentifier: activity?.activityIdentifier as string,
          };

          return this.progressSrv.createUnscheduledProgress<IProgress>(progress, state.patient.selectedId)
        }),
      )),
      switchMap((progress: IProgress | null) => {
        return [progress ? loadProgressSuccess({ progress }) : progressFail({ error: '' }), loadActivities()]
      }),
      catchError((e: any) => {
        console.log(e);
        return of(progressFail({ error: e?.message || e }));
      })
    ),
  )
}
